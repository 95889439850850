.ant-select-selector {
  padding: 12px !important;
  border: 1px solid #e9e9e9 !important;
  background-color: transparent !important;
}

.ant-select-single {
  height: 53px !important;
}

.ant-select-selector:hover {
  border: 1px solid #212b36 !important;
}

.ant-select-focused .ant-select-selector {
  border: 1px solid #212b36 !important;
  outline: 1px solid #212b36 !important;
}

.ant-select-arrow {
  font-size: 13px !important;
}

.ant-select-selection-placeholder {
  font-family:
    Public Sans,
    sans-serif !important;
  font-size: 0.875rem !important;
  color: #919eab !important;
}

.ant-select-selection-item {
  font-family:
    Public Sans,
    sans-serif !important;
}

.ant-select-dropdown {
  z-index: 1300 !important;
}
